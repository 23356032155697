import React from 'react';

import SkateboardDeck from '../assets/images/skate-deck-pixelated.png'

const SKATEBOARD_IMG_WIDTH = 1965;
const LEFT_PAD = 626;
const RIGHT_PAD = 575;
const PADDING_LEFT = `${LEFT_PAD/SKATEBOARD_IMG_WIDTH*100}%`;
const PADDING_RIGHT = `${RIGHT_PAD/SKATEBOARD_IMG_WIDTH*100}%`;

export default function ({ children }) {
    return (
        <div style={{position: 'relative'}}>
            <img src={SkateboardDeck} style={{ width: '100%' }} alt="" />
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT, display: 'flex', justifyContent: 'center' }}>
                {children}
            </div>
        </div>
    );
}
