import React, { useState } from 'react';

import FullBackgroundImage from '../components/FullBackgroundImage';
import Background from '../assets/images/options-background.jpg';
import { InstagramButton } from '../components/SocialButton';
import BlackBox from '../components/BlackBox';
import Breadcrumb from '../components/Breadcrumb';


export default function About() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

    const isMobile = windowWidth <= 600;
    return (
        <FullBackgroundImage src={Background}>
            <div style={{ paddingTop: 25 }}></div>
            <Breadcrumb text="Bad Realm" />
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "min(800px, 95%)" }}>
                    <div style={{ margin: 80 }}></div>
                    <BlackBox style={{ textAlign: 'center', padding: isMobile ? 15 : 40 }}>
                        <p style={{ color: "white", fontFamily: "PressStart2P", margin: 0, fontSize: isMobile ? 14: 18, lineHeight: 1.3, padding: [20, 0] }}>
                            Bad Realm Records. Based in Los Angeles in partnership with Atlantic Records.
                    </p>
                    </BlackBox>
                    <div style={{ margin: 15 }}></div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <InstagramButton href="https://www.instagram.com/badrealmrecords" width={60} />
                    </div>
                    <div style={{ margin: 15 }}></div>
                </div>
            </div>
        </FullBackgroundImage>
    );
}
