import React from 'react';

import FacebookLogo from '../assets/images/facebook-logo.png';
import SpotifyLogo from '../assets/images/spotify-logo.png';
import InstagramLogo from '../assets/images/instagram-logo.png';
import TikTokLogo from '../assets/images/tiktok-logo.png';
import MusicNote from '../assets/images/music_note-24px.svg';

const PADDING = 3;

function SocialButton({href, target, imageSrc, alt, width, padding}) {
    width = width || 40;
    padding = padding || PADDING;
    return (
    <div>
        <a href={href} target={target} style={{textDecoration: "none", color: "white", fontFamily: "PressStart2P", fontSize: 34}}>
            <div style={{
                backgroundColor: "#000",
                padding,
                borderRadius: 100,
                display: "flex",
                justifyContent: "center",
            }}>
                <img src={imageSrc} style={{width: width - 2*padding, height: width - 2*padding, transform: imageSrc === InstagramLogo ? 'scale(1.2)': null, objectFit: 'contain'}} alt={alt}/>
            </div>
        </a>
    </div>
    );
}

export function FacebookButton({href}) {
    return <SocialButton href={href} target='_blank' imageSrc={FacebookLogo} alt='Facebook Logo'/>
}

export function InstagramButton({href, width}) {
    return <SocialButton href={href} width={width} target='_blank' imageSrc={InstagramLogo} alt='Instagram Logo'/>
}

export function SpotifyButton({href}) {
    return <SocialButton href={href} target='_blank' imageSrc={SpotifyLogo} alt='Spotify Logo'/>
}

export function TikTokButton({href}) {
    return <SocialButton href={href} imageSrc={TikTokLogo} alt='TikTok' padding={5}/>
}

export function ReleasesButton({href}) {
    return <SocialButton href={href} imageSrc={MusicNote} alt='Releases'/>
}
