import React, { useState } from 'react';

import FullBackgroundImage from '../components/FullBackgroundImage';
import Background from '../assets/images/options-background.jpg';
import OrangeArrow from '../assets/images/orange-arrow.svg';
import Skateboard from '../components/Skateboard';
import BlackBox from '../components/BlackBox';
import { RELEASES } from '../artistData';
import AspectRatioDiv from '../components/AspectRatioDiv';
import Breadcrumb from '../components/Breadcrumb';

import SpotifyLogoWide from '../assets/images/Spotify_Logo_RGB_White@2x.png';
import YoutubeLogoWide from '../assets/images/yt_logo_mono_dark@2x.png';
import SoundcloudLogoCircle from '../assets/images/soundcloud-circle-button.svg';
import Hoverable from '../components/Hoverable';
import { useLocation } from 'react-router-dom';
import BadRealmLogoSkateboard from '../components/BadRealmLogoSkateboard';

const TRACK_BUTTONS = {
    spotify: {
        backgroundColor: '#1DB954',
        padding: 5,
        borderRadius: 10,
        imgWidth: '80%',
        image: SpotifyLogoWide,
    },
    youtube: {
        backgroundColor: '#FF0000',
        padding: 12.5,
        borderRadius: 10,
        imgWidth: '80%',
        image: YoutubeLogoWide,
    },
    soundcloud: {
        backgroundColor: '#00000000',
        padding: 0,
        borderRadius: 1000,
        imgWidth: '100%',
        image: SoundcloudLogoCircle,
    },
}

function TrackLinkButton({ type, url }) {
    return (
        <div>
            <a href={url} style={{ textDecoration: "none", color: "white", fontFamily: "PressStart2P", fontSize: 24 }} target="blank">
                <div style={{
                    backgroundColor: TRACK_BUTTONS[type].backgroundColor,
                    padding: TRACK_BUTTONS[type].padding,
                    borderRadius: TRACK_BUTTONS[type].borderRadius,
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    border: "4px solid white"
                }}>
                    <img src={TRACK_BUTTONS[type].image} style={{ width: TRACK_BUTTONS[type].imgWidth, height: 'intrinsic', objectFit: 'contain' }} alt={`Listen to track on ${type}`} />
                </div>
            </a>
        </div>
    );
}

function DownloadLinkButton({ url }) {
    return (
        <div>
            <a href={url} style={{ textDecoration: "none", color: "white", fontFamily: "PressStart2P", fontSize: 24 }} target="blank">
                <div style={{
                    backgroundColor: "#000",
                    padding: 12.5,
                    borderRadius: 10,
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    border: "4px solid white",
                    fontSize: 18,
                }}>
                    Download
                </div>
            </a>
        </div>
    );
}


function PageController({ onPrevPress, onNextPress, currentPage, nPages }) {
    const showPrevButton = currentPage > 0;
    const showNextButton = currentPage !== nPages - 1;
    return (
        <div style={{ display: 'inline-block' }}>
            <div style={{ padding: '20px 20px', borderRadius: 1000, display: 'flex', alignItems: 'center', border: '1px solid #F04F1F', backgroundColor: '#000000' }}>
                <Hoverable style={{ visibility: showPrevButton ? 'visible' : 'hidden' }}>
                    <img src={OrangeArrow} style={{ height: 50, marginRight: 30, marginLeft: 30 }} onClick={onPrevPress} alt="Go to previous page" />
                </Hoverable>
                <span style={{ fontSize: 26, textTransform: 'uppercase', textAlign: 'center' }}>Page {currentPage + 1}/{nPages}</span>
                <Hoverable style={{ visibility: showNextButton ? 'visible' : 'hidden' }}>
                    <img src={OrangeArrow} style={{ height: 50, marginRight: 30, marginLeft: 30, transform: 'rotate(180deg)' }} onClick={onNextPress} alt="Go to next page" />
                </Hoverable>
            </div>
        </div>
    );
}

function computeTracksPerPage() {
    if (window.innerWidth >= 1200) return 3;
    if (window.innerWidth >= 800) return 2;
    return 1;
}

export default function () {
    const location = useLocation();
    const artistName = decodeURIComponent(location.pathname.split('/releases').pop().split('/').pop());

    const [tracksPerPage, setTracksPerPage] = useState(computeTracksPerPage());
    window.addEventListener('resize', () => setTracksPerPage(computeTracksPerPage()));
    const [pageNum, setPageNum] = useState(0);
    const releases = RELEASES.map(r => r).filter(r => r.artist.name === artistName || artistName.length === 0);
    const numPages = Math.ceil(releases.length / tracksPerPage);
    if (pageNum >= numPages) {
        setPageNum(numPages - 1);
    }
    const releasesShown = releases.slice(pageNum * tracksPerPage, (pageNum + 1) * tracksPerPage);

    const isPageControllerOnBottom = tracksPerPage <= 1;
    const pageController = <PageController
        currentPage={pageNum}
        nPages={numPages}
        onPrevPress={() => setPageNum(pageNum - 1)}
        onNextPress={() => setPageNum(pageNum + 1)}
    />;
    return (
        <FullBackgroundImage src={Background}>
            <div style={{ paddingTop: 30 }}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb text="Releases" />
                {!isPageControllerOnBottom &&
                    <div>
                        {pageController}
                        <div style={{ paddingRight: 40, display: 'inline-block' }}></div>
                    </div>
                }
            </div>
            <div style={{ paddingTop: 30 }}></div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                {releasesShown.map(r => <BlackBox style={{ width: 275 }} key={`${r.artist.name}: ${r.title}`}>
                    <div style={{ padding: "35px 35px 20px 35px", textAlign: 'center' }}>
                        <AspectRatioDiv ratio={1 / 1}>
                            <img src={r.art} style={{ width: '100%', height: 'auto' }} alt={`"${r.title}" by ${r.artist.name} album art`} />
                        </AspectRatioDiv>
                        <div style={{ marginTop: 0, marginLeft: -80, marginRight: -80 }}>
                            <Skateboard>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: 140 / r.artist.name.length }}>{r.artist.name}</span>
                                </div>
                            </Skateboard>
                        </div>
                        <h3 style={{ fontSize: 18, margin: "35px -20px" }}>"{r.title}"</h3>
                        <div style={{ display: 'flex', alignItems: 'center', margin: r.soundcloud_link != null ? "0 -20px" : null }}>
                            {r.soundcloud_link &&
                                // <div style={{ marginLeft: -0, marginRight: -0 }}>
                                //     <iframe width="60" height="60" scrolling="no" frameBorder="no" allow="autoplay" title={`${r.title} by ${r.artist.name}`}
                                //         src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(r.soundcloud_link)}`}>
                                //     </iframe>
                                // </div>
                                <div style={{ marginRight: 15 }}>
                                    <TrackLinkButton type="soundcloud" url={r.soundcloud_link} />
                                </div>
                            }
                            <div>
                                {r.spotify_link &&
                                    <TrackLinkButton type="spotify" url={r.spotify_link} />
                                }
                                {r.youtube_link &&
                                    <TrackLinkButton type="youtube" url={r.youtube_link} />
                                }
                            </div>
                        </div>
                        {r.itunes_link &&
                            <div style={{ margin: r.soundcloud_link != null ? "0 -20px" : null }}>
                                <DownloadLinkButton url={r.itunes_link} />
                            </div>
                        }
                    </div>
                </BlackBox>)}
            </div>
            {isPageControllerOnBottom &&
                <div>
                    <div style={{ height: 120 }}></div>
                    <div style={{ position: 'fixed', bottom: 15, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {pageController}
                    </div>
                </div>
            }
        </FullBackgroundImage>
    );
}
