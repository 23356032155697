// Step 1: Add and import artist pictures
import BrelandPic from './assets/images/artists/breland.png';

// Step 2: Add and import release cover art
import BrelandCover from './assets/images/album_art/breland-cover.png';
import MyTruckCover from './assets/images/album_art/mytruck-album.png';
import RageAndSorrow from './assets/images/album_art/rage_and_sorrow_album_art.png'

// Step 3: Declare an artist here using the artist picture from Step 1
const BRELAND = {
    name: 'BRELAND',
    description: 'Known as “The Pen Point Guard,” New Jersey-born BRELAND began writing songs as a teenager. While attending Georgetown University, he knocked on every industry door possible. Upon graduation, BRELAND moved to Atlanta and continued to pursue music in between holding down a day job and launching a budding business as a vocal coach. A year-long tireless grind yielded a string of high-profile placements. Between crafting songs for Ar’mon & Trey and Ann Marie, he contributed to six cuts on YK Osiris’s debut ‘The Golden Child’ in addition to “Let You Pick” for ELHAE’s ‘Trouble In Paradise.’ At every turn, he lived up to the nickname of “The Pen Point Guard.” Now, with “My Truck,” BRELAND rewrites the rulebook from scratch as a solo artist. “My vision is limitless and clear,” BRELAND says. “I don’t subscribe to rules. I’ll make whatever I like. I’m trying to innovate. I’m a music fan, first and foremost. I spend so much time studying what’s going on in order to create something different. I’m fusing a lot of separate styles. I want to have a place on the cutting edge.”',
    image: BrelandPic,
    spotify_link: 'https://open.spotify.com/artist/0C86lmpnwiyLDUiyo4d0P1?si=xNpZ4_o6SZmfTNt6jaIdgw',
    instagram_link: 'https://www.instagram.com/breland/?hl=en',
    facebook_link: 'https://www.facebook.com/brelandthepenpointguard/',
    tiktok_link: 'https://www.tiktok.com/@breland',
};

// Step 4: Add the artist to this list
export const ARTIST_DATA = [
    BRELAND,
];

// Step 5: Add releases using the artist object from Step 3, and the album art from Step 2
export const RELEASES = [
    {
        artist: BRELAND,
        title: 'Rage & Sorrow',
        art: RageAndSorrow,
        spotify_link: 'https://open.spotify.com/album/2HW7iJc1rQ8b2NC6Yz0W2z?si=QVkSgxNeS1KNFkTeb4_uzg',
        youtube_link: 'https://www.youtube.com/watch?v=RHinveoL9_E&list=PLeAyn48io_pjAQEEXaltikxsRzFPvUxBP&index=2&t=0s',
        soundcloud_link: 'https://soundcloud.com/brelandmusic/sets/rage-sorrow',
        apple_music_link: 'https://music.apple.com/us/album/rage-sorrow-single/1519058267',
        itunes_link: 'https://geo.itunes.apple.com/album/1519058267?uo=4&at=1010lKkX&lId=21937648&cId=none&sr=3&src=Linkfire&itscg=30440&itsct=catchall_p3&app=itunes&ct=LFV_9ab29d72d39165853780cf4218b3fe8e&ls=1',
    },
    {
        artist: BRELAND,
        title: 'My Truck',
        art: MyTruckCover,
        spotify_link: 'https://open.spotify.com/track/6jGyDvL1Rf4XfwTb25o1HN?si=_-LobnWcTs-dTmiOi3lKCw',
        youtube_link: 'https://www.youtube.com/watch?v=ezqTO4xaH6A',
        soundcloud_link: 'https://soundcloud.com/brelandmusic/breland-my-truck',
        apple_music_link: 'https://music.apple.com/ph/album/my-truck-single/id1494240991',
        itunes_link: 'https://geo.itunes.apple.com/album/1513635172?i=1513635488&at=1001lL6h&lId=21448947&cId=none&sr=4&src=Linkfire&itscg=30440&itsct=catchall_p4&app=itunes&ct=LFV_c47e53541e3fef1966b3376083e7ea1e&ls=1',
    },
    {
        artist: BRELAND,
        title: 'BRELAND - EP',
        art: BrelandCover,
        spotify_link: 'https://open.spotify.com/album/61oSbcEqPRT4bTnP4YCQvt',
        youtube_link: 'https://www.youtube.com/playlist?list=PLeAyn48io_pjEhMH4wgfnds4IZure22hA&src=Linkfire&lId=33fbc47d-9ea7-4692-96d8-ff393d00b11a&cId=d3d58fd7-4c47-11e6-9fd0-066c3e7a8751',
        soundcloud_link: 'https://soundcloud.com/brelandmusic/sets/breland',
        apple_music_link: 'https://music.apple.com/us/album/breland-ep/1513635172',
        itunes_link: 'https://itunes.apple.com/us/album/id1513635172?ls=1&at=1010lKkX&lId=21817947&cId=none&sr=3&src=Linkfire&itscg=30440&itsct=catchall_p3&app=itunes&ct=LFV_ba261e08b0c995767e0de0d4a30630a1',
    },
];
