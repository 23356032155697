import React from 'react';
import BadRealmLogoSkateDeck from '../assets/images/bad-realm-skate-deck-pixelated.png';

const SKATEBOARD_LOGO_PADDING = 4;

export default function ({ href }) {
    return (
        <a href={href}>
            <img src={BadRealmLogoSkateDeck} style={{ width: '100%', padding: SKATEBOARD_LOGO_PADDING, objectFit: 'contain' }} alt='Bad Realm Records Logo' />
        </a>
    );
}
