import React, { useState } from 'react';

import OrangeArrow from '../assets/images/orange-arrow.svg';
import BadRealmLogo from '../assets/images/badrealmlogo.png';

function computeIsMobile() {
    return window.innerWidth < 600;
}

export default function ({ text }) {
    const [isMobile, setIsMobile] = useState(computeIsMobile());
    window.addEventListener('resize', () => setIsMobile(computeIsMobile()));
    return (
        <a style={{ display: 'inline-block', textDecoration: 'none' }} href="/">
            <div style={{
                padding: isMobile ? '15px 40px' : '20px 80px',
                borderRadius: 1000,
                transform: isMobile ? 'translateX(-40px)' : 'translateX(-80px)',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #F04F1F',
                backgroundColor: '#000000',
            }}>
                <img src={OrangeArrow} style={{ height: isMobile ? 32 : 50, marginRight: 30, marginLeft: 30 }} alt="Go back arrow" />
                <h1 style={{ color: '#F04F1F', fontSize: isMobile ? 16 : 30, textTransform: 'uppercase' }}>{text}</h1>
                {/* <img src={BadRealmLogo} style={{ height: isMobile ? 50 : 75, marginRight: 0, marginLeft: isMobile ? 40 : 75 }} alt="Bad Realm Logo" /> */}
            </div>
        </a>
    );
}
