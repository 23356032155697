import React, { useState } from 'react';
import Background from '../assets/images/main-menu-background.jpg';
import FullBackgroundImage from '../components/FullBackgroundImage';
import MenuButton from '../components/MenuButton';
import { InstagramButton } from '../components/SocialButton';
import BadRealmLogoSkateboard from '../components/BadRealmLogoSkateboard';

function computeIsMobile() {
    return window.innerWidth <= 600;
}

export default function Home() {
    const [isMobile, setIsMobile] = useState(computeIsMobile());
    window.addEventListener('resize', () => setIsMobile(computeIsMobile()));
    const menuButtonFontSize = isMobile ? 16 : 24;
    const menuButtonPadding = isMobile ? 10 : 50;
    const horizontalPadding = isMobile ? 10 : 40;
    return (
        <FullBackgroundImage src={Background}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: 700, maxWidth: "100vw", minHeight: "100vh" }}>
                <div style={{ padding: `0 ${horizontalPadding}px`, width: `calc(100% - ${2 * horizontalPadding}px)` }}>
                    <BadRealmLogoSkateboard />
                    <div style={{ width: "100%" }}>
                        <div style={{ paddingLeft: menuButtonPadding, paddingRight: menuButtonPadding }}>
                            <MenuButton href="/badrealm" fontSize={menuButtonFontSize}>Bad Realm</MenuButton>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <InstagramButton href="https://www.instagram.com/badrealmrecords" width={60} />
                    </div>
                </div>
            </div>
        </FullBackgroundImage>
    );
}
