import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Artists from './pages/Artists';
import Releases from './pages/Releases';
import About from './pages/About';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/badrealm" component={About} />
      {/* redirect user to Home page if route does not exist */}
      <Route component={Home} />
    </Switch>
  );
}
