import React from 'react';

export default function({src, ...props}) {
    return <div style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: "100vh",
        minWidth: "100%",
    }}>
        {props.children}
    </div>;
}
