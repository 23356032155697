import React, { useState } from 'react';
import Hoverable from './Hoverable';

export default function ({ href, fontSize, ...props }) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Hoverable setIsHovered={setIsHovered}>
            <a href={href} style={{ textDecoration: 'none', color: isHovered ? '#F04F1F' : 'white', fontSize, transition: '0.1s', textTransform: 'uppercase' }}>
                <div style={{
                    backgroundColor: '#000d',
                    borderRadius: 100,
                    margin: 15,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 90,
                    boxSizing: 'border-box',
                    transition: '0.1s',
                    ...(isHovered ? {borderWidth: 2, borderColor: '#F04F1F', borderStyle: 'solid'} : {})
                }}>
                    {props.children}
                </div>
            </a>
        </Hoverable>
    );
}
