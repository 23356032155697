import React from 'react';

export default function ({ children, style }) {
    style = style || {};
    const padding = style.padding || 20;
    return (
        <div style={{ backgroundColor: "#000000E6", borderRadius: 25, width: `calc(100% - ${2*padding}px)`, padding, ...style }}>
            {children}
        </div>
    );
}
