import React from 'react';

export default function ({ setIsHovered, style, children, ...props }) {
    setIsHovered = setIsHovered || (() => null);
    return (
        <div {...props} style={{cursor: 'pointer', ...style}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {children}
        </div>
    );
}
